import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ContactAlertComponent } from './pages/_layout/components/contact-alert/contact-alert.component';
import { CommonModule } from '@angular/common';
import { HttpLoaderFactory } from './app.module';
import { HttpClient } from '@angular/common/http';

@NgModule({
  exports: [
    TranslateModule, ContactAlertComponent
  ],
  declarations: [
    ContactAlertComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }), 
  ],
})

export class SharedModule { }