import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router : Router) {}

  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
    //const currentUser = this.authService.currentUserValue;
    const currentCoach = this.authService.currentCoachValue;
    const token = localStorage.getItem('token');

    if (token) {
      var qp = route.queryParams
      if (qp.action) {
        localStorage.setItem('action', qp.action);
      }
      if (qp.friend) localStorage.setItem('friend', qp.friend);
      if(qp && qp.emailToken){
        localStorage.setItem('token', qp.emailToken);
        var url = state.url
        var urlCut = url.split('?')
        this.router.navigate([urlCut[0]])

        if (qp.forceToken) {
          let newUrl = window.location.href.split('?')[0];
          let searchParams = new URLSearchParams(window.location.search);
          searchParams.delete('forceToken');
          if (searchParams.toString()) {
              newUrl += '?' + searchParams.toString();
          }
      
          window.history.pushState({}, '', newUrl);
          window.location.reload();
      }

      }
       //logged in so return true
      return true;
    }else{

      let params = route.queryParams;
      let paramsToCheck = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];
      let utm_datas: any = {};

      for (let param of paramsToCheck) {
        if (params[param] != undefined) {
          utm_datas[param] = params[param];
        }
      }   
      if(Object.keys(utm_datas).length > 0)
        sessionStorage.setItem('utm_datas', JSON.stringify(utm_datas));

       var qp = route.queryParams
       if (qp.action) {
        localStorage.setItem('action', qp.action);
      }
      var url = state.url
      if(qp && qp.emailToken){
        localStorage.setItem('token', params.emailToken);
        var urlCut = url.split('?')
        localStorage.setItem('location', urlCut[0])
        localStorage.setItem('emailToken', qp.emailToken)
        this.authService.getCoachByToken();
        return false;
      }else{

        if (qp.friend) {
          localStorage.setItem('friend', qp.friend);
          this.router.navigate(['auth/registration'])
          return false;
        }

        // not logged in so redirect to login page with the return url
        this.authService.logout();
        return false;
      }
    }
  }
}
