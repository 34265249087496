import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, Subject } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { param } from 'jquery';
import { Crisp } from 'crisp-sdk-web';

declare let gtag: Function;
const API_USERS_URL = environment.apiUrl;
const API_USERS_URL_BASE = environment.apiUrlBase;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  
  // still ok for v2 
  isLoadingSubject: BehaviorSubject<boolean>;
  isLoadingDataSubject : BehaviorSubject<any>;
  checkEmailSubject : BehaviorSubject<boolean>;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  
  
  // attributes to delete later 
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  isLoadingData$: Observable<any>;
  checkEmail$ :Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;

  isbillingavailable$ : Observable<boolean>;
  isbillingavailableSubject : BehaviorSubject<any>;
  getNextValueisbillingavailable() : Observable<any>{

    return this.isbillingavailableSubject.asObservable();   
  }

  setNextValueisbillingavailable(billing){
    this.isbillingavailableSubject.next(billing);
  }


  getNextLoadingData() : Observable<any>{
    return this.currentCoachSubject.asObservable();
      
  }

  //to pass messages from one component to another
  messageSource = new BehaviorSubject(undefined);
  currentMessage = this.messageSource.asObservable();

  availableLoginSubject = new BehaviorSubject(undefined);
  availableLogin = this.availableLoginSubject.asObservable();


  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  // new fields 
  private headers= new HttpHeaders('Content-Type: application/x-www-form-urlencoded; charset=UTF-8; Access-Control-Allow-Headers:*');
  
  getNextValueIsLoading() : Observable<any>{

    return this.isLoadingSubject.asObservable();   
  }
  // fieds and functions to get and set current coach profile infos
  currentCoachSubject: BehaviorSubject<any>;
  currentCoach$: Observable<any>;

  get currentCoachValue(): any {
    return this.currentCoachSubject.value;
  }
  getNextValueCoach() : Observable<any>{
    return this.currentCoachSubject.asObservable();
      
  }

  setValueInCoach(token : string ){
    this.getCoachByTokenHttp(token).subscribe(data=>{
      this.currentCoachSubject.next(data);
      
    })   
}

  // fieds and functions to get and set current coach supports
  supportsList$ : Observable<any>
  supportListSubject : BehaviorSubject<any>;

  get supportsListValue(): any {
    return this.supportListSubject.value;
  }

  getNextValueSupportsList() : Observable<any>{
    return this.supportListSubject.asObservable(); 
  }

  setValueInSupportsList(token){
    this.getSupportsList(token).subscribe(data=>{
      this.supportListSubject.next(data);
    }) 
  }

  // fieds and functions to get and set prestation list for calendar
  prestationsListForCalendar$ : Observable<any>
  prestationsListForCalendarSubject : BehaviorSubject<any>;

  get prestationsListForCalendarValue(): any {
    return this.prestationsListForCalendarSubject.value;
  }

  getNextValuePrestationsListForCalendar() : Observable<any>{
    return this.prestationsListForCalendarSubject.asObservable(); 
  }
  
    // fieds and functions to get and set prestations fiches
    prestationFiches$ : Observable<any>
    prestationFichesSubject : BehaviorSubject<any>;
  
    get prestationFichesValue(): any {
      return this.prestationFichesSubject.value;
    }
  
    getNextValueprestationFiches() : Observable<any>{
      return this.prestationFichesSubject.asObservable(); 
    }
  
    setValueInprestationFiches(token,su_id,with_prestations_list){
      this.ApiGetFichesPrestations(token,su_id,with_prestations_list).subscribe(data=>{
        this.prestationFichesSubject.next(data);
      }) 
    }

  activated_support$ : Observable<any>
  activated_supportSubject : BehaviorSubject<any>;

  get activated_supportValue(): any {
    return this.supportListSubject.value;
  }

  getNextValueactivated_support() : Observable<any>{
    return this.activated_supportSubject.asObservable(); 
  }

  setValueInactivated_support(su_number){
      this.activated_supportSubject.next(su_number);
  }

  show_current_support$ : Observable<any>
  show_current_supportSubject : BehaviorSubject<any>;

  get show_current_supportValue(): any {
    return this.show_current_supportSubject.value;
  }

  getNextValueshow_current_support() : Observable<any>{
    return this.show_current_supportSubject.asObservable(); 
  }

  setValueInashow_current_support(istrue){
      this.show_current_supportSubject.next(istrue);
      
  }

  // fieds and functions to get and set prestations for a specific support
  prestationsList$ : Observable<any>
  prestationsListSubject : BehaviorSubject<any>;

  get prestationsListValue(): any {
    return this.supportListSubject.value;
  }
  getNextValuePrestationsList() : Observable<any>{
    return this.prestationsListSubject.asObservable(); 
  }
  setValueInPrestationsList(token,lang,su_id){
    this.getPrestationsList(token,lang,su_id).subscribe(data=>{
      this.prestationsListSubject.next(data);
    }) 
  }
  setObsPrestationsList(data){
    this.prestationsListSubject.next(data)
  }
  // fieds and functions to get and set  specific support infos
  supportInfos$ : Observable<any>
  supportInfosSubject : BehaviorSubject<any>;

  get supportInfosValue(): any {
    return this.supportInfosSubject.value;
  }
  getNextValuesupportInfos() : Observable<any>{
    return this.supportInfosSubject.asObservable(); 
  }
  setValueInsupportInfos(token,su_id){
    this.getSupportInfos(token,su_id).subscribe(data=>{
      this.supportInfosSubject.next(data);
    }) 
  }
  setObsInSupportInfos(data){
    this.supportInfosSubject.next(data)
  }

  // fieds and functions to get and set  specific support infos
  last_presta_validate$ : Observable<any>
  last_presta_validateSubject : BehaviorSubject<any>;

  get last_presta_validateValue(): any {
    return this.last_presta_validateSubject.value;
  }
  getNextValuelast_presta_validate() : Observable<any>{
    return this.last_presta_validateSubject.asObservable(); 
  }
  setValueInlast_presta_validate(value){
      this.last_presta_validateSubject.next(value); 
  }


 // fieds and functions to get and set current coach profile infos
 ProfileUncompleteSubject: BehaviorSubject<boolean>;
 ProfileUncomplete$: Observable<boolean>;


 get currentProfileUncompleteValue(): any {
   return this.ProfileUncompleteSubject.value;
 }
 getNextValueProfileUncomplete() : Observable<any>{
   return this.ProfileUncompleteSubject.asObservable();
     
 }

 setValueInProfileUncomplete(value : boolean ){
     this.ProfileUncompleteSubject.next(value);
}

 // fieds and functions to get and set current formation infos
 currentCoachFormationSubject: BehaviorSubject<any>;
 currentCoachFormation$: Observable<any>;

 get currentCoachFormationValue(): any {
   return this.currentCoachFormationSubject.value;
 }

 getNextValueCoachFormation() : Observable<any>{

   return this.currentCoachFormationSubject.asObservable();   
 }

 setValueInCoachFormation(token : string){
   this.coachFormation(token).subscribe(data=>{
     this.currentCoachFormationSubject.next(data.results);
   })   
}



   // fieds and functions to get and set profile picture url 
    currentPicSubject: BehaviorSubject<any>;
    currentPic$: Observable<any>;

    get currentPicValue(): any {
      return this.currentPicSubject.value;
    }
    getNextValuePic() : Observable<any>{
      return this.currentPicSubject.asObservable();
        
    }
    setValueInPic(url : any ){
        this.currentPicSubject.next(url);
    }

  // fieds and functions to get and set profile steps 
    currentStepSubject: BehaviorSubject<any>;
    currentSteps$: Observable<any>;

    get currentStepValue(): any {
        return this.currentStepSubject.value;
    }

    getNextValueSteps() : Observable<any>{
      return this.currentStepSubject.asObservable();
        
    }
    setValueInSteps(token:any ){
      this.stepCoachProfile(token).subscribe(data=>{
        this.currentStepSubject.next(data);
      }) 
    }
  
  // fieds and functions to get and set the active route
  currentIsActivatedSubject: BehaviorSubject<any>;
  isActivated$: Observable<any>;

  get currentIsActivatedValue(): any {
      return this.currentIsActivatedSubject.value;
  }

  getNextValueIsActivated() : Observable<any>{
    return this.currentIsActivatedSubject.asObservable();
      
  }
  setValueInIsActivated(activatedRoutes:any){
    this.currentIsActivatedSubject.next(activatedRoutes);
  }

  private buttonQuitClickSubject = new Subject<void>();
  buttonQuitClick$ = this.buttonQuitClickSubject.asObservable();

  // Method to emit buttonQuit click event everywhere in the application and open /profile/quit from ProfileComponent
  emitButtonQuitClick() {
    this.buttonQuitClickSubject.next();
  }

  constructor(
    private http: HttpClient,
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {

    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();

    // when loading data 
    this.isLoadingDataSubject = new BehaviorSubject<any>(false);
    this.isLoadingData$ = this.isLoadingDataSubject.asObservable();
    
    
    this.isbillingavailableSubject  = new BehaviorSubject<any>(undefined);
    this.isbillingavailable$ =this.isbillingavailableSubject.asObservable();


    // to observe the value of uncompleteSatut 
    this.ProfileUncompleteSubject = new BehaviorSubject<boolean>(false);
    this.ProfileUncomplete$ = this.ProfileUncompleteSubject.asObservable();

    this.checkEmailSubject = new BehaviorSubject<boolean>(false);
    this.checkEmail$ = this.checkEmailSubject.asObservable();
   
    this.currentCoachSubject = new BehaviorSubject<any>(undefined);
    this.currentCoach$ = this.currentCoachSubject.asObservable();

    this.currentCoachFormationSubject = new BehaviorSubject<any>(undefined);
    this.currentCoachFormation$ = this.currentCoachFormationSubject.asObservable();
    
    this.currentPicSubject = new BehaviorSubject<any>(undefined);
    this.currentStepSubject = new BehaviorSubject<any>(undefined);
    this.currentSteps$ = this.currentStepSubject.asObservable();

    this.currentIsActivatedSubject = new BehaviorSubject<any>(undefined);
    this.isActivated$ = this.currentIsActivatedSubject.asObservable();
    
    this.supportListSubject = new BehaviorSubject<any>(undefined);
    this.supportsList$ = this.supportListSubject.asObservable();

    this.prestationFichesSubject = new BehaviorSubject<any>(undefined);
    this.prestationFiches$ = this.prestationFichesSubject.asObservable();

    this.prestationsListSubject = new BehaviorSubject<any>(undefined);
    this.prestationsList$ = this.prestationsListSubject.asObservable();

    this.supportInfosSubject = new BehaviorSubject<any>(undefined);
    this.supportInfos$ = this.supportInfosSubject.asObservable();

    this.activated_supportSubject = new BehaviorSubject<any>(undefined);
    this.activated_support$ = this.activated_supportSubject.asObservable();

    this.show_current_supportSubject = new BehaviorSubject<any>(undefined);
    this.show_current_support$ = this.activated_supportSubject.asObservable();

    this.last_presta_validateSubject= new  BehaviorSubject<any>(undefined);
    this.last_presta_validate$ =  this.last_presta_validateSubject.asObservable();
    
    
    this.prestationsListForCalendarSubject = new  BehaviorSubject<any>(undefined);
    this.prestationsListForCalendar$ =this.prestationsListForCalendarSubject.asObservable();
  
    const subscribeCoach = this.getCoachByToken().subscribe();
    this.unsubscribe.push(subscribeCoach);

    this.smallScreenSubject = new BehaviorSubject<boolean>(undefined)
    this.smallScreen$ = this.smallScreenSubject.asObservable();
  }

 // adapted functions from V1 
 // connexion 
  loginCoach(email : string , password : string ) {
    this.isLoadingSubject.next(true); // for the spinner
    return this.http.post<any>(API_USERS_URL + '/secure/login',
         {
            login: email,
            password: password,
            us_role : 2,
            access_type: "app_web"
         },
         {
           headers: this.headers
         })
         .pipe(map(infos => {
            //La connexion est réussie s'il y a un jeton JWT dans la réponse
            // verifie qu'il y a un token dans la réponse et les infos de base du profil
            if(infos.success){
             
              if (infos.results.token && infos.results.profil_infos) {
                this.setValueInCoach(infos.results.token);
                this.setInfosInLocalStorage(infos.results.token, infos.results.profil_infos);
                return infos;
              }
              else {
                this.isLoadingSubject.next(false);
                return infos.msg;
                
              }
            }
            else {
              var errorMessage =  infos.msg
              this.isLoadingSubject.next(false);
              return errorMessage
            }
         },
         ),
         catchError((err) => {
          return throwError(err.message);
        }
         ));
  }

 

  // récupère les données du coach à partir du token 
  getCoachByToken(): Observable<any> {
    const coach = this.getCoachFromLocalStorage();
    var emailToken = localStorage.getItem('emailToken')
    var actualLocation = localStorage.getItem('location')

    if (!coach.coachData || ! coach.token ) {
      if(emailToken){
        this.getCoachByEmailToken(emailToken).subscribe((data)=>{
          if(data.success && data.valid_token){
            this.setValueInCoach(emailToken)
            this.setInfosInLocalStorage(emailToken, data.results)
            localStorage.removeItem('emailToken')
            localStorage.removeItem('location')
            setTimeout(()=>{
              this.router.navigate([actualLocation])
            }, 1000)
            
          }else{
            localStorage.clear()
            this.logout()
            this.isLoadingSubject.next(false)
            return of(undefined)
          }
        })
      }else{
        return of(undefined);
      }
    }

    this.isLoadingSubject.next(true);
    
    return this.getCoachByTokenHttp(coach.token).pipe(
      map((user: any) => {
        if (user) {

          if(!user.success && !user.token_valid){
            this.logout();
          }
          this.currentCoachSubject = new BehaviorSubject<any>(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  getCoachByEmailToken(emailToken :string)
  {  
      return this.http.post<any>(API_USERS_URL+'/coach/profile_infos',{
      token: emailToken,
      next_status_available: true,
      update_last_activity: true
      }).pipe(
        catchError((err) => {
          this.logout()
          return of(undefined)
        }))

  }

 getCoachByTokenHttp(token :string)
  {  
      return this.http.post<any>(API_USERS_URL+'/coach/profile_infos',{
      token: token,
      next_status_available: true,
      update_last_activity: true
      }).pipe(
        catchError((err) => {
          this.logout()
          return of(undefined)
        }))

  }
  updateStatusCoach(token: string, status_id: number){//update the status of the coach
    return this.http.post<any>(API_USERS_URL+'/coach/update_status',{
      token: token,
      new_status : status_id
    })
  }

  //get coach steps
  stepCoachProfile(token: string): Observable<any>
  {
      return this.http.post<any>(API_USERS_URL+'/coach/coach_profile_steps', 
      {
        token:token
      })        
      .map(data => { 
        return data.results          
      });                 
  }
  
  private setInfosInLocalStorage( token : string , coach : any ) : boolean{
    if(token && coach){
      
      //stocke le jeton jwt dans le stockage local pour que l'utilisateur reste connecté           
      localStorage.setItem('token', token );
      localStorage.setItem('infos_profile',JSON.stringify(coach));
      return true ;
    }
    return false;
  }



  private getCoachFromLocalStorage(): any {
    try {
      const coachData = 
        localStorage.getItem('infos_profile')
      
      const token =
        localStorage.getItem('token')
      return ({
        coachData : coachData,
        token : token
      });
    } catch (error) {
      console.error(error);
      return throwError(error.message);
    }
  }


  // need create new user then login
  registrationCoach(coach: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.createCoach(coach).pipe(
      map((data) => {
        if (data.success == true ) {
          localStorage.removeItem('friend');
          gtag('event', 'event_new_coach_register', {
              'event_category': 'register',
              'event_label': 'New register in coach app'
          });
          if (data.direct_link_url_sent && !data.results) {
              localStorage.setItem('ExistingEmail', coach.us_email);
              this.router.navigate(['auth/waiting']);
          } else {
            this.setValueInCoach(data.results.new_token.token);
            localStorage.setItem('token', data.results.new_token.token);
            localStorage.setItem('infos_profile', JSON.stringify(data.results.new_token.profil_infos));
            document.location.reload();
          }
          return data;
        }
       else {
        var errorMessage =  data.msg
        this.isLoadingSubject.next(false);
        return errorMessage
      }
      
      }),
      catchError((err) => {
        console.error('err', err);
        return throwError(err.message);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

   // CREATE =>  POST: add a new user to the server
  createCoach(coach: any): Observable<any> {
    coach.access_type = "app_web",
    coach.utm_datas = JSON.parse(sessionStorage.getItem('utm_datas')!)
    return this.http.post<any>(API_USERS_URL_BASE +'2/coach/create_account', coach ,{
      headers: this.headers
    }).pipe(
      catchError((err) => {
        return throwError(err.message);
      }));
  }

  logout() {
    localStorage.clear()
    //sessionStorage.clear()
    setTimeout(() => {
      this.router.navigateByUrl('/auth').then(() => {
      
        if (environment.isCrispAvailable) {
          Crisp.setTokenId("");
          Crisp.session.reset();
          //Crisp.chat.show();
          Crisp.chat.hide(); //Par défaut Crisp est masqué (on le montre que pour les personnes connectée)
        }
      });
    },1000);
  }


  //Email de connexion à l'app
  sendConnexionLink(email: string) {

    return this.http.post<any>(API_USERS_URL + '/secure/send_connexion_link',
       {
        login: email,
          access_type: 'app_web',
          us_role: 2
          //base_url:'https://coach.mysherpa.be/'
       },
       {
         headers: this.headers
       }).pipe(
        catchError((err) => {
          return throwError(err.message);
        }))
 }


  //Récupéreration du mot de passe
  retrievePassword(email: string) {

    return this.http.post<any>(API_USERS_URL + '/secure/retrieve_pwd',
       {
          login_or_email: email,
          version:2
          //base_url:'https://coach.mysherpa.be/'
       },
       {
         headers: this.headers
       }).pipe(
        catchError((err) => {
          return throwError(err.message);
        }))
 }

  //Verification du token lors de la creation d'un nouveau mot de passe
  verificationToken(token: string) {

    return this.http.post<any>(API_USERS_URL + '/secure/check_recovery_token',
       {
          pwd_recovery_token: token
       },
       {
         headers: this.headers
       }).pipe(
        catchError((err) => {
          return throwError(err.message);
        }))
  }

  //Creation d'un nouveau mot de passe
  createNewPassword(login:string,password: string, token: string) {

      return this.http.post<any>(API_USERS_URL + '/secure/create_pwd',
        {
            login:login,
            pwd: password,
            pwd_recovery_token: token
        },
        {
          headers: this.headers
        }).pipe(
          catchError((err) => {
            return throwError(err.message);
          }))
  }
  //Vérification de la disponibilité de l'email donné par le coach lors de l'incription
  //send_connection_email Permet d'envoyer dans la foulée le lien de connexion 
  loginAvailable(email:string, send_connection_email: boolean = false):Observable<any> {
    this.isLoadingSubject.next(true);
    this.checkEmailSubject.next(false); // false 
    return this.http.post<any>(API_USERS_URL_BASE+'2/secure/is_login_available',
      {
        lang: 'fr',
        us_role: 2,
        login: email,
        send_connection_email: send_connection_email

      },
      {
        headers: this.headers
      }).pipe(
        catchError((err) => {
          return throwError(err.message);
        }))
  }


  // to pass data 
  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  // update photo de profil 

  updatepic(picture:any){
    return this.http.post<any[]>(API_USERS_URL + '/coach/update_profile', { profile_picture : picture})
  }
  //Mise à jour du profile du coach
  updateProfile(info: any, token: string, us_id: string) {

    return this.http.post<any[]>(API_USERS_URL + '/coach/update_profile', {
      token: token,
      us_id: us_id,
      profile_picture : info.profile_picture,
      us_lang: info.us_lang,
      us_firstname: info.us_firstname,
      us_lastname: info.us_lastname,
      us_sex: info.us_sex,
      us_birthdate: info.us_birthdate,
      us_national_number : info.us_national_number,
      us_email: info.us_email,
      us_gsm: info.us_gsm,        
      online_profile: info.online_profile,          
      us_address: info.us_address,
      us_zip: info.us_zip,
      us_city: info.us_city,
      country:{
        id: info.country.id
      }
      }).pipe(
        catchError((err) => {
          return throwError(err.message);
        }))
         
  }

  getDocuments(token: string, us_id: any, cr=false){
    return this.http.post<any[]>(API_USERS_URL  + '/coach_docs/list', {
      token: token,
      us_id: us_id,
      cr: cr
    })
  }

  updateDocuments(token: string, doc_type_id: string, doc_content: any){
    return this.http.post<any[]>(API_USERS_URL + '/coach_docs/update', {
      token: token,
      doc_type_id: doc_type_id,
      doc_content: doc_content
      })
  }

     //Permet de mettre à jour les informations comptables du coach
     updateComptabilite(info: any ,token: string ,us_id: string)
     {        
         return this.http.post<any>(API_USERS_URL + '/coach/update_profile',
           {
                token: token,
                us_id: us_id,
                contract_sub_type: {id : info.contract_sub_type},
                tva_cat: { id:info.tva_cat},                                      
                vat_number : info.vat_number,
                us_national_number: info.us_national_number,
                co_bic: info.co_bic,
                co_iban: info.co_iban,
                company_infos:{
                          name: info.name,
                          address : info.address,
                          zip : info.zip,
                          city : info.city,
                            country : { id: info.country}
                        }
              }).pipe(
                catchError((err) => {
                  return throwError(err.message);
                }))                        
     }

  //récuperation de la liste des pays
  getCountriesList(public_token:string)
  {
    return this.http.post<any>(API_USERS_URL+'/countries_list',
      {    
        token:public_token,
        limit: 400
      })

  }

//Mise à jour du mot de passe du coach
updatePassword(password: any, token: string, us_id: string) {
  return this.http.post<any[]>(API_USERS_URL + '/coach/update_profile', {
    token: token,
    us_id: us_id,
    password: password
    })
       
}

//récupérer les compétences disponibles pour un coach
getSkillsList(token: string, us_lang: any) {
  return this.http.post<any[]>(API_USERS_URL + '/branches', {
    token: token,
    us_lang: us_lang,
    limit: 400,
  })
}

//liste de toutes les langues 
getLanguages_list(token:string ,us_lang:any){
  return this.http.post<any[]>(API_USERS_URL  + '/languages_list', {
    token: token,
    us_lang: us_lang,
  })
}

//Mettre à jour les compétences du coach
updateAptitudes(aptitudes: any, languages_list: any, token: string) {
  return this.http.post<any[]>(API_USERS_URL + '/coach/update_aptitudes',{
    aptitudes: aptitudes,
    languages_list:languages_list,
    token: token
  }).pipe(
    catchError((err) => {
      return throwError(err.message);
    }))
    
}

//Mise à jour de la description du coach
updateDescription(us_id: string, token: string, info:any) {
  return this.http.post<any>(API_USERS_URL  + '/coach/update_profile',
    {
      us_id: us_id,
      token: token,
      cv:
      {
        description: info.cv
      }
    })

}

//mettre à jour le status du coach
updateStatutCoach(info: any, token: string, us_id: string) {
  return this.http.post<any[]>(API_USERS_URL + '/coach/update_profile', {
    token: token,
    us_id: us_id,
    status:{
      id: info.status.id
    }
    })
       
} 

    //mise à jour de la formation du  coach
    
    updateEducation(info:any,token:string, us_id: string) {

      return this.http.post<any>(API_USERS_URL +'/coach/update_education',{
        token: token,
        us_id: us_id,
        type:
        {
          id: info.situation,
          
        },
        last_school_year: info.last_school_year,
        sc_year:
        {
          id: info.sc_year,
          
        },
        school:
        {
          id: info.school,
          
        },
        other_school_name: info.other_school_name,
        faculty:
        {
          id: info.faculty,
        
        },
        other_faculty_name: info.other_faculty_name,
        industry:
        {
          id: info.industry,
         
        },
        other_industry_name: info.other_industry_name,
        has_car: info.has_car       
      
      }).pipe(
        catchError((err) => {
          return throwError(err.message);
        }))   
  } 


occupations_list(token: string, us_lang: any) {
  return this.http.post<any[]>(API_USERS_URL+ '/occupations_list', {
    token: token,
    us_lang: us_lang,
    limit: 400,
  })
}

 //récupérer la liste des années scolaires en cours
school_years(token:string ,us_lang:any) 
{
return this.http.post<any[]>(API_USERS_URL+ '/school_years', {
  token: token,
  us_lang: us_lang,
}
)}

 //récuperer la liste des universités
 getSchoolList(token: string, us_lang)
 {
   return this.http.post<any>(API_USERS_URL+'/schools_list',
     {    
       token:token,
       us_lang: us_lang,
       level_id: 3,
       limit: 400
     })
 }

 //récuperer la liste des facultés
 getFacultiesList(token: string, us_lang: string)
 {
   return this.http.post<any>(API_USERS_URL+'/faculties_list',
     {    
       token: token,
       us_lang: us_lang,
       limit: 400
     })

 }

 //récuperer la formation du coach

 coachFormation(token :string)   
 {  
   return this.http.post<any>(API_USERS_URL+'/coach/get_education',{
     token: token
     })        
   }

  //récupérer les domaines d'activités disponibles pour un coach

  industries_list(token: string ,us_lang:any) {
    return this.http.post<any>(API_USERS_URL+ '/industries_list',
      {
        token: token,
        us_lang: us_lang,
        limit: 400
      }
    )}


    //methode pour recuperer le type de contrat a mettre dans les infos comptables
    getTypeContract(token: string, us_lang:any)
    {
      return this.http.post<any>(API_USERS_URL +'/contracts_sub_types_list',
        {    
          token: token,
          us_lang: us_lang
        })

    }

getTvaList(token: string, us_lang:any)
    {
      return this.http.post<any>(API_USERS_URL +'/vat_cat_list',
        {    
          token: token,          
          us_lang: us_lang
        })

    }

      //récuperation de la liste des pays visibles par le coach
getCountriesCoachList(public_token:string)
      {
        return this.http.post<any>(API_USERS_URL +'/countries_list',
          {    
            token:public_token,
            coach_visible: 1
          })

      }

     

    // permet de récupérer les périodes de disponibilité d'un coach  
getPeriodsCoach(token :string, us_id: string, us_lang:any) {
    return this.http.post<any>(API_USERS_URL+ '/coach/get_year_availabilities',
      {
        token: token,
        us_id: us_id,
        us_lang: us_lang
      })
  }

      // Mettre à jour les périodes de disponibilité du coach   
  updatePeriodsCoach(token:string, us_id: string, avalabilities: any) {
    return this.http.post<any>(API_USERS_URL+ '/coach/update_year_availabilities',
      {
        token: token,
        us_id: us_id,
        avalabilities: avalabilities
      })
  }


      //récuperer les zones de disponibilités du coach

      getCoachPlaces(token:any) {
        return this.http.post<any>(API_USERS_URL + '/coach/get_coach_places',
          {
            token: token,
            manage_legal_place: true       
          })
      }

        //  Ajouter une zone de disponibilité du coach

      addCoachPlace(token,zone) {
        
        return this.http.post<any>(API_USERS_URL + '/coach/update_coach_place',
          {
            token: token,
            address: zone.address,
            zip: zone.zip,
            city: zone.city,
            country: zone.country,
            week_availabilities : zone.week_availabilities
          })
      }

      // Permet de modifier une zone de disponibilité du coach

      modifCoachPlace(token,zone) {
        
          return this.http.post<any>(API_USERS_URL + '/coach/update_coach_place',
            {
              token: token,
              id:zone.id,
              address:zone.address,
              zip:zone.zip,
              city:zone.city,
              type :{ id :  zone.type.id },
              country:zone.country,
              week_availabilities:zone.week_availabilities,
            })      
      }

      deleteCoachPlace(token,zone_id) {
      return this.http.post<any>(API_USERS_URL + '/coach/update_coach_place',
          {
            token: token,
            action: 'delete',
            id:zone_id,
          },
          )
      }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
 /////////////////////////////////////////////////////////////////////
  // Appels pour la partie Agenda & Eleves (-> Soutiens et prestations)
  /////////////////////////////////////////////////////////////////////
  /**
   * Récupère la liste des soutiens lié à un coach
   * @param token 
   * @param lang 
   * Le token étant lié à un utilisateur, l'id de l'utilisateur n'est pas requis ici
   */
  getSupportsList(token)
  {
    return this.http.post<any>(API_USERS_URL + '/supports/supports_infos',
    {
      token: token,
      with_disorders: true,
      with_documents: true
    },
    {
      headers: this.headers
    }) 
  }

  /**
   * Récupère la liste des soutiens lié à un coach
   * @param token 
   * @param su_id 
   * Le token étant lié à un utilisateur, l'id de l'utilisateur n'est pas requis ici
   */
  getSupportInfos(token,su_id,with_coach_prestations?:boolean)
  {
    return this.http.post<any>(API_USERS_URL + '/supports/supports_infos',
    {
      token: token,
      su_id: su_id,
      with_disorders: true,
      with_coach_prestations: with_coach_prestations,
      default_course_place: true
    },
    {
      headers: this.headers
    }) 
  }

  /**
   * Récupère la liste des prestations liées à un soutien
   * @param token 
   * @param lang 
   * @param su_id Id de l'utilisateur
   */
  getPrestationsList(token,lang,su_id,id_presta?)
  {
    if(id_presta){
      return this.http.post<any>(API_USERS_URL + '/supports/prestations/prestationslist',
    {
      token: token,
      lang: lang,
      su_id: su_id,
      id : id_presta,
      default_course_place : true
    },
    {
      headers: this.headers
    })  
    }
    else{
      return this.http.post<any>(API_USERS_URL + '/supports/prestations/prestationslist',
    {
      token: token,
      lang: lang,
      su_id: su_id,
      default_course_place : true
    },
    {
      headers: this.headers
    })  
    }
    
  }

  //
  /**
   * Permet d'ajouter une prestation à un soutien
   * @param token 
   * @param su_id Id de l'utilisateur
   * @param date Date de la prestation au format ISO
   * @param hour Heure de début de la prestation
   * @param duration Durée de la prestation (Format nombre, 2h30 -> 2.5)
   * @returns Retourne l'id de la prestation crée
   */
  apiAddPrestation(token,su_id,date,hour,duration)
  {
    return this.http.post<any>(API_USERS_URL+ '/supports/prestations/addprestation',
    {
      token: token,
      su_id: su_id,
      date_prestation:date,
      heure_prestation:hour,
      duree_prestation:duration
    },
    {
      headers: this.headers
    })  
  }
  /**
   * Permet de supprimer une prestation liée à un soutien
   * @param token 
   * @param su_id Id de l'utilisateur
   * @param id_presta Id de la prestation
   */
  apiDeletePrestation(token,su_id,id_presta)
  {
    return this.http.post<any>(API_USERS_URL + '/supports/prestations/deleteprestation',
    {
      token: token,
      su_id: su_id,
      id:id_presta,
    },
    {
      headers: this.headers
    })  
  } 
  /**
   * Permet de mettre à jour les dates et horaires d'une prestation 
   * @param token 
   * @param su_id Id de l'utilisateur
   * @param id_presta Id de la prestation
   * @param date Date de la prestation au format ISO
   * @param hour Heure de début de la prestation
   * @param duration Durée de la prestation (Format nombre, 2h30 -> 2.5)
   */
  apiUpdatePrestation(token,su_id,id_presta,date,hour,duration)
  {
    return this.http.post<any>(API_USERS_URL + '/supports/prestations/updateprestationdates',
    {
      token: token,
      su_id: su_id,
      id:id_presta,
      date_prestation:date,
      heure_prestation:hour,
      duree_prestation:duration
    },
    {
      headers: this.headers
    })  
  } 
  /**
   * Permet de mettre à jour le compte rendu lié à un soutien
   * @param token 
   * @param su_id Id du soutien
   * @param id_presta Id de la prestation
   * @param compte_rendu Compte rendu de la séance
   * @param devoirs Devoirs à faire pour la prochaine séance
   * @param note Score de motivation de la séance
   */
  apiUpdatePrestationNotes(token,su_id,id_presta,course_place)
  {
    return this.http.post<any>(API_USERS_URL + '/supports/prestations/updateprestationnotes',
    {
      token: token,
      su_id: su_id,
      id:id_presta,
      course_place : {'id' : course_place}
    },
    {
      headers: this.headers
    })  
  }
  /**
   * Permet de changer le statut d'une prestation
   * @param token, token de la session
   * @param su_id, id du soutien associé
   * @param id_presta, id de la prestation
   * @param new_status, nouveau statut
   */
  apiUpdatePrestationStatus(token,su_id,id_presta,new_status){
    return this.http.post<any>(API_USERS_URL+ '/supports/prestations/updateprestationstatus',
    {
      token: token,
      su_id: su_id,
      id:id_presta,
      status_prestation_id:new_status,
    },
    {
      headers: this.headers
    })  
  }
  /**
   * Permet de valider une prestation
   * @param token, token de la session
   * @param su_id, id du soutien associé
   * @param id_presta, id de la prestation
   * @param new_status, nouveau statut = 2 : validée
   * @param compte_rendu, 
   * @param devoirs,
   * @param note, note de motivation
   * @param course_place_id, id du lieu
   */
  apiValidatePrestation(token,su_id,id_presta,new_status,imported_prestation){

    var params = {
      token: token,
      su_id: su_id,
      id:id_presta,
      status_prestation_id:new_status,
      compte_rendu : imported_prestation.compte_rendu,
      devoirs : imported_prestation.devoirs,
      note :  imported_prestation.note,
      course_place : {'id' : imported_prestation.course_place.id}
    };
    if(imported_prestation.students !== undefined && imported_prestation.students ){
      params['students'] = imported_prestation.students ;
      
    }

    return this.http.post<any>(API_USERS_URL+ '/supports/prestations/updateprestationstatus',
    params,
    {
      headers: this.headers
    })  
  }
  /**
   * Récupère l'ensemble des prestations entre deux dates
   * @param token 
   * @param us_id Id de l'utilisateur
   * @param date_start Date à partir de laquelle on cherche les prestations
   * @param date_end Date jusqu'à laquelle on cherche les prestations
   */
  getPrestationsCalendar(token,us_id,date_start,date_end) 
  {
    return this.http.post<any>(API_USERS_URL+ '/prestations/prestationslist_for_calendar',
    {
      token:token,
      us_id:us_id,
      date_start:date_start,
      date_end:date_end
    },
    {
      headers: this.headers
    })  
  }

/**
   * Gestion des fiches de prestations, onglet mes paiements
   * 
   */
  ApiGetFichesPrestations(token,su_id,with_prestations_list) 
  {
    return this.http.post<any>(API_USERS_URL+ '/supports/fiches_prestations/get',
    {
      token:token,
      su_id:su_id,
      with_prestations_list:with_prestations_list,
    },
    {
      headers: this.headers
    })  
  }

/**
   * Create fiche
   * 
   */
  ApiCreateFichePrestation(token,su_id) 
  {
    return this.http.post<any>(API_USERS_URL+ '/supports/fiches_prestations/create',
    {
      token:token,
      su_id:su_id,
    },
    {
      headers: this.headers
    })  
  }

  getSupportsDocuments(su_id: string): Observable<any> {
    return this.http.post<any>(
      API_USERS_URL+ '/supports/supports_docs_list',
      { token: localStorage.getItem('token'), su_id: su_id }
    )
  }


/**
   * Home Page Info
   * 
   */
  ApiGetHomePageInfos(token,us_id) 
  {
    return this.http.post<any>(API_USERS_URL+ '/coach/homepage_infos_details',
    {
      token:token,
      us_id:us_id,
    },
    {
      headers: this.headers
    })  
  }

// Mettre à jour la date de retour du coach
  
APIupdatePauseDate(token:string, us_id:string, returnDate:any) {
  return this.http.post<any>(API_USERS_URL + '/coach/update_year_availabilities',
    {
      token: token,
      us_id: us_id,
      co_full_end_date: returnDate
    })
} 

//mettre à jour si le coach quit ou réintègre l'équipe
APIupdateQuit(token, us_id, leaveDate, reasons) {
  return this.http.post<any>(API_USERS_URL + '/coach/update_date_leave_team',
    {
      token: token,
      us_id: us_id,
      co_leave_date: leaveDate,
      co_reason_leave_id:reasons,       
    })
}

APIgetReasonsLeaveList(token: string,us_lang: any)
{
  return this.http.post<any>(API_USERS_URL+'/coach/coaches_reasons_leave_list',
  {    
    token: token,
    us_lang: us_lang
  })      
}
//Survey Zone
private enqueteDispo = new BehaviorSubject<boolean>(false);
enquete = this.enqueteDispo.asObservable();

private refresherPage = new BehaviorSubject<boolean>(false);
refresher = this.refresherPage.asObservable();

theRefresh(arg){
  this.refresherPage.next(arg)
}

changeEnquete(enquete: boolean){
  this.enqueteDispo.next(enquete)
}

APIGetSurveys(token: string, with_questions:boolean, su_id, token_answer_text_only:boolean, tk_status_id2, support, sv_token_id?){
  return this.http.post<any>(API_USERS_URL+'surveys/token_list',{
    token: token,
    su_id:su_id,
    with_questions: with_questions,
    sv_token_id: sv_token_id,
    token_answer_text_only:token_answer_text_only,
    tk_status_id2: tk_status_id2,
    support: support,
  })
}

APICompleteSurvey(token: string, id, new_status: string, survey){
  return this.http.post<any>(API_USERS_URL + 'surveys/update_token',{
    token: token,
    id:id,
    new_status: new_status,
    survey: survey,
  })
}

APiRefererGenerateId(token: string) {
  return this.http.post<any>(API_USERS_URL + 'referer/generateId', {
      token
  });
}

APiRefererVerify(refererId: string) {
  return this.http.post<any>(API_USERS_URL + 'referer/verifyrefererid', {
      token : "7bea3c49115f93678cc86035990905cf",
      refererId
  });
}

APIGetReferers(token: string) {
  return this.http.post<any>(API_USERS_URL + 'referer/view', {
    token
  });
}

APICheckCode(code: string, email: string ) {
  return this.http.post<any>(environment.apiUrl + 'secure/gettokenbycode', {code, email, access_type:'app', token: '7bea3c49115f93678cc86035990905cf'});
}

APIGetIcaCalendar(su_id: string, token: string ) {
  return this.http.post<any>(environment.apiUrl + 'supports/geticalbutton', {su_id, token});
}

/* smallscreen zone */

smallScreen$ : Observable<boolean>
smallScreenSubject : BehaviorSubject<boolean>

get smallScreenValue(): any {
  return this.smallScreenSubject.value;
}
getSmallScreenValue() : Observable<any>{
  return this.smallScreenSubject.asObservable(); 
}
setSmallScreenValue(bool : boolean){
  this.smallScreenSubject.next(bool);
}

  //Permet de reporter un bug vers bugherd
  APIReportBug(token,content,versionApp)
  {
    return this.http.post<any>(API_USERS_URL + '/reportBug',
    {
      token: token,
      content: content,
      versionApp: versionApp,
      source: "web"
    },
    {
      headers: this.headers
    })  
  }

  /*
  getCondition(token: string, us_lang: any){

   
      return this.http.post<any>(API_USERS_URL+'/company_infos',{
        token: token,
        us_lang: us_lang,
      })
    }
    */
    
    getBilan(token: string, us_lang: any){

   
      return this.http.post<any>(API_USERS_URL+'/coaches/accounting/getCoachAnnualStatements',{
        token: token,
        us_lang: us_lang,
      })
    }

    getStatutsDetails(token: string, us_lang: any){

   
      return this.http.post<any>(API_USERS_URL+'/coach/legal_status_details',{
        token: token,
        us_lang: us_lang,
      })
    }


    getCoachInvoicesAndPaiements(token: string, us_lang: any, year: any){

   
      return this.http.post<any>(API_USERS_URL+'/coaches/accounting/getCoachInvoicesAndPaiements',{
        token: token,
        us_lang: us_lang,
        year: year,

        
      })
    }

  //Fonction appelée après un login, check token, creation compte,...;
  //Sur base infos profile_infos du localStorage
    public init_crisp(profil_infos: {
      phone_wa_format_plus: string;
      crisp_segment: any; us_id: string; email: string; firstname: string; lastname: string; role: any; phone: string; 
}) {

    let routeSnapshot = this.router.routerState.snapshot.root;
      while (routeSnapshot.firstChild) {
          routeSnapshot = routeSnapshot.firstChild;
      }
      const queryParams = routeSnapshot.queryParams;

      if (environment.isCrispAvailable && !queryParams.silentLog) {

        //Si token pas encore set pour le user connecté, on le set
        if(window.CRISP_TOKEN_ID != profil_infos.us_id){
          window.CRISP_TOKEN_ID = profil_infos.us_id;
          Crisp.session.reset();
        }
  
        Crisp.user.setEmail(profil_infos.email);
        Crisp.user.setNickname(profil_infos.firstname + " " + profil_infos.lastname);
        Crisp.user.setPhone(profil_infos.phone_wa_format_plus);
  
        Crisp.session.setData({
          user_id: profil_infos.us_id,
          user_status: profil_infos.crisp_segment,
          user_role: profil_infos.role     
        });
  
        //Crisp.session.setSegments(["student",profil_infos.crisp_segment], true); //Permet de ré-init tous les segments mais on perd ceux automatiques donc desactivé
        
        // Crisp.chat.show();
        Crisp.chat.hide();
      }
    }
  }




