import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/modules/auth/_services/auth.service';

@Component({
  selector: 'app-contact-alert',
  templateUrl: './contact-alert.component.html',
  styleUrls: ['./contact-alert.component.scss']
})
export class ContactAlertComponent {
  @Input() id_soutient: string;
  @Input() admin_email: string;
  @Input() admin_tel_format: string;
  @Input() profile_picture: string;
  @Input() us_firstname: string;
  @Input() us_lastname: string;
  @Input() us_sex: string;

  mail: string = "";
  tel: string = "";
  picture: string = "";
  firstname: string = "";
  lastname: string = "";
  sex: string = "";


  current_infos_profile: any = null;

  constructor(private auth: AuthService) {

  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.auth.getNextValueCoach().subscribe((infos:any)=>
    {
      this.current_infos_profile = infos.results;

      if (!this.id_soutient) {
        // Si affiché dans un soutien
        // console.log("[CONTACT] current_infos_profile", this.current_infos_profile);
  
        this.mail = this.current_infos_profile.antenne.admin.admin_email
        this.tel = this.current_infos_profile.antenne.admin.admin_tel_format
        this.picture = this.current_infos_profile.antenne.admin.profile_picture
        this.firstname = this.current_infos_profile.antenne.admin.us_firstname
        this.lastname = this.current_infos_profile.antenne.admin.us_lastname
        this.sex = this.current_infos_profile.antenne.admin.us_sex     
      } else {
        // Si affiché dans dans le menu
        this.mail = this.admin_email
        this.tel = this.admin_tel_format
        this.picture = this.profile_picture
        this.firstname = this.us_firstname
        this.lastname = this.us_lastname
        this.sex = this.us_sex
      }
    })
  }
}
