<!--begin::Alert-->
<div class="alert bg-light-primary d-flex flex-row justify-content-center align-items-center px-4 py-8 my-5 w-100">
    <!--begin::Symbol-->
    <div class="symbol symbol-circle symbol-90 mr-7">
      <div
        class="symbol-label"
        style="background-image: url({{picture}})"
      ></div>
      <i class="symbol-badge bg-success"></i>
    </div>
    <!--end::Symbol-->

    <!--begin::Wrapper-->
    <div class="d-flex flex-column pe-0 pe-sm-10">
        <!--begin::Title-->
        <h3 class="text-primary font-weight-bold" [translate] = "'contact.title'">Besoin d'aide?</h3>
        <p class="p-0 m-0">
          <span class="font-weight-bold">{{ firstname }} {{ lastname }}</span>,
          <ng-container *ngIf="sex === 'F'; else man_title">
            <span class="" [translate] = "'contact.title_F'">votre conseillère pédagogique est à votre disposition.</span>
          </ng-container>
          <ng-template #man_title>
            <span class="" [translate] = "'contact.title_H'">votre conseiller pédagogique est à votre disposition.</span>
          </ng-template>
        </p>
        
        <!--end::Title-->

        <!--begin::Content-->
        <small class="opacity-75 mb-3" [translate] = "'contact.opening_hours'">Du lundi au vendredi : 8h30 - 12h30 et 13h30 - 17h30</small>
        <!--end::Content-->

        <div class="d-flex flex-row justify-content-start">
          <a class="symbol symbol-circle symbol-20 mr-4"  href="tel:+{{ tel }}" target="_blank">
            <div class="symbol-label bg-primary p-5">
              <i class="fas fa-phone text-white"></i>
            </div>
          </a>
          <a class="symbol symbol-circle symbol-20 mr-4" href="https://api.whatsapp.com/send?phone={{tel}}&text={{ id_soutient ? 'ref:' + id_soutient : '' }}%0D%0A{{current_infos_profile.us_firstname}}+{{current_infos_profile.us_lastname}}%0D%0ACoach+My+Sherpa" target="_blank">
            <div class="symbol-label bg-primary p-5">
              <img src="../../../../../assets/media/client-logos/whatsapp.svg" alt="Whatsapp logo">
            </div>
          </a>
          <a class="symbol symbol-circle symbol-20" href="mailto:{{ mail }}" target="_blank">
            <div class="symbol-label bg-primary p-5">
              <i class="fas fa-envelope text-white"></i>
            </div>
          </a>
        </div>
    </div>
    <!--end::Wrapper-->

  </div>
  <!--end::Alert-->