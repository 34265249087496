import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  ElementRef,
  Renderer2,
  Inject,
} from '@angular/core';
import { TranslationService } from './modules/i18n/translation.service';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd ,ActivatedRoute} from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { ConnectionService } from 'ng-connection-service';  
import { Title } from '@angular/platform-browser';
import { Crisp } from "crisp-sdk-web";

import  'rxjs/add/operator/filter';
import  'rxjs/add/operator/map';
import  'rxjs/add/operator/mergeMap'
import {filter} from "rxjs/operators";
import {environment} from '../environments/environment';
import { Capacitor } from '@capacitor/core';
import OneSignal from 'onesignal-cordova-plugin';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { DOCUMENT } from '@angular/common';

declare let gtag: Function;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  status = 'ONLINE';
  isConnected = true;
  g4a_param: any = {}; // declare g4a_param as an empty object
  platform: string;

  private mutationObserver: MutationObserver;


  constructor(
    private translate : TranslateService,
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private connectionService: ConnectionService,
    private titleService : Title,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    
    if (environment.isCrispAvailable) {
      //Configuration de Crisp Chatbox
      const crisp_options = {
        locale: translate.currentLang,
        sessionMerge: false,
        autoload: false
      }
      Crisp.configure(environment.crisp_website_id, crisp_options); 
      
      //Si utilisateur connecté, -> tokenId via us_id utilisé pour la session
      if(localStorage.getItem('infos_profile')) {
        window.CRISP_TOKEN_ID = JSON.parse(localStorage.getItem('infos_profile')!).us_id;
      }
  
      //Ajout infos liée à l'app courante
      Crisp.session.setData({
        role: "coach",
        app_type: "web",
        app_version: environment.appVersion
      });
      
      //Set Session Segments
      Crisp.session.setSegments(["coach"], false);

      Crisp.chat.hide(); //Par défaut Crisp est masqué (on le montre que pour les personnes connectée)
    }


    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
      }
      else {
        this.status = "OFFLINE";
      }
    })
    // register translations
   // this.translationService.loadTranslations(
    //  enLang,
    //  chLang,
    //  esLang,
    //  jpLang,
    //  deLang,
    //  frLang
    //);
  }

  ngOnInit() {
    this.platform = Capacitor.getPlatform();

    if (this.platform !== "web") {
      // Start observing changes in the body attributes
      this.startObservingAttributes();

      // Lock screen on portrait
      ScreenOrientation.lock({
        orientation: 'portrait'
      });
    }

        this.setUpAnalytics();
        //Affichage du titre de la page en fontion du Routage
        this.router.events
        .filter((event) => event instanceof NavigationEnd)
        .map(() => this.activatedRoute)
        .map((route) => {
            while (route.firstChild) 
                route = route.firstChild;
            return route;
        })
        .filter((route) => route.outlet === 'primary')
        .mergeMap((route) => route.data)
        .subscribe((event) => 
            {  
             
                this.titleService.setTitle(this.translate.instant(event['title']));  

            }
        );

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');  
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);

    this.modifyTitle();
    this.modifyMetaTags();
    this.modifyLogo();
    this.modifyFavIcon();

    //Only Native Logic (ios/android)
    if (Capacitor.isNativePlatform()) {
      this.oneSignalInit();
    }

  }

  setUpAnalytics() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
            this.g4a_param.page_path = event.urlAfterRedirects;

            // Ajouter la plateforme à la configuration
            this.g4a_param.platform = Capacitor.getPlatform();

            // Si env non production, on active mode 'debug_mode':true
            // To disable debug mode, exclude the 'debug_mode' parameter; setting the parameter to false doesn't disable debug mode.
            if (!environment.production){
                this.g4a_param.debug_mode = true;
            }
            gtag('config', 'G-4H5XVF7LL5',
            this.g4a_param
          );
        });
  }

  modifyTitle() {
    const titleElement = this.document.querySelector('title');
    if (titleElement) {
      this.renderer.setProperty(titleElement, 'innerText', environment.brand_name);
    }
  }

  modifyMetaTags() {
    const metaElements = this.document.querySelectorAll('meta');
    if (metaElements) {
      metaElements.forEach((metaElement: HTMLMetaElement) => {
        const property = metaElement.getAttribute('property');
        const name = metaElement.getAttribute('name');
        if (property === 'og:title' || property === 'twitter:title' || name === 'title') {
          this.renderer.setAttribute(metaElement, 'content', `Join our coach Team | ${environment.brand_name}`);
        } 
      });
    }
  }

  modifyLogo() {
    const logoElement = this.document.getElementById("splash-screen-image-1");
    if (logoElement) {
      this.renderer.setAttribute(logoElement, 'src', `./assets/media/logos/logo_${environment.brand_slug}.svg`);
    }
  }

  modifyFavIcon() {
    const favIconElement = this.document.querySelectorAll('link');

    if (favIconElement) {
      favIconElement.forEach((element) => {
        const rel = element.getAttribute('rel');
        if (rel === 'icon') {
          this.renderer.setAttribute(element, 'href', `./assets//media//logos//favicon_${environment.brand_slug}.ico`);
        } 
      });
    }

  }

  /**
   * Start observing changes in the 'data-offcanvas-offcanvas' attribute of the body.
   */
  startObservingAttributes() {
    const body = document.body;

    // Create a MutationObserver to watch for changes in attributes
    this.mutationObserver = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.attributeName === 'data-offcanvas-offcanvas') {
          
          const offcanvasValue = body.getAttribute('data-offcanvas-offcanvas');
          this.lockBodyScrollIfOffcanvas(offcanvasValue);
        }
      });
    });

    // Start observing the body for attribute changes
    this.mutationObserver.observe(body, {
      attributes: true,
      attributeFilter: ['data-offcanvas-offcanvas'],
    });
  }

  /**
   * Lock body scroll based on the value of 'data-offcanvas-offcanvas'.
   * @param value - The value of 'data-offcanvas-offcanvas'.
   */
  lockBodyScrollIfOffcanvas(value: string | null) {
    
    const body = document.body;

    if (value === 'on') {
      this.renderer.setStyle(body, 'bottom', '0');
      this.renderer.setStyle(body, 'left', '0');
      this.renderer.setStyle(body, 'position', 'fixed');
      this.renderer.setStyle(body, 'right', '0');
      this.renderer.setStyle(body, 'top', '0');
    } else {
      this.renderer.removeStyle(body, 'bottom');
      this.renderer.removeStyle(body, 'left');
      this.renderer.removeStyle(body, 'position');
      this.renderer.removeStyle(body, 'right');
      this.renderer.removeStyle(body, 'top');
    }
  }

  ngOnDestroy() {
    if (this.platform !== "web") {
      ScreenOrientation.unlock();

      this.mutationObserver.disconnect();
    }

    this.unsubscribe.forEach((sb) => sb.unsubscribe());

  }

  oneSignalInit(): void {
    // Uncomment to set OneSignal device logging to VERBOSE  
    // OneSignal.Debug.setLogLevel(6);
    
    // Uncomment to set OneSignal visual logging to VERBOSE  
    // OneSignal.Debug.setAlertLevel(6);
  
    // NOTE: Update the init value below with your OneSignal AppId.
    OneSignal.initialize(environment.one_signal_app_id);
    
    let myClickListener = async function(event) {
      let notificationData = JSON.stringify(event);
    };
    OneSignal.Notifications.addEventListener("click", myClickListener);
  
    // Prompts the user for notification permissions.
    OneSignal.Notifications.requestPermission(true).then((accepted: boolean) => {
    });
  }


}
