import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


Sentry.init({
  dsn: "https://ca9cd91e0ef44b6c85fd671360cc681a@o319454.ingest.sentry.io/5594329",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "https://msdev01.mysherpa.be/api/1/"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  environment : environment.environment,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.0, //1.0 pour activer, 0.0 pour désactiver,
  // Ajoutez cette ligne pour désactiver la capture des événements d'erreurs
  beforeSend: () => null
});

Sentry.setTag("appVersion", environment.appVersion);

if (environment.production) {
  enableProdMode();
}

if(!environment.production){
  Sentry.createErrorHandler({
    showDialog: true})
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
