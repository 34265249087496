import { NgModule, APP_INITIALIZER , ErrorHandler} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/_services/auth.service';
import { environment } from 'src/environments/environment';
import { ToastrModule } from 'ngx-toastr';
import {ConnectionServiceModule} from 'ng-connection-service';   
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatIconModule } from "@angular/material/icon";
// Sentry 
import * as Sentry from "@sentry/angular";
// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import highlight from 'highlight.js/lib/highlight';
import xml from 'highlight.js/lib/languages/xml';
import json from 'highlight.js/lib/languages/json';
import scss from 'highlight.js/lib/languages/scss';
import typescript from 'highlight.js/lib/languages/typescript';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';

//Angular traslation(Module de traduction de langue)
import {TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';


// pour le formatage de date 
import { registerLocaleData } from '@angular/common';
import localeFR from "@angular/common/locales/fr-BE";
import localeNL from "@angular/common/locales/nl-BE";

import { AgmCoreModule } from '@agm/core';
import { environment as env } from 'src/environments/environment';

import { SharedModule } from './shared.module';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

registerLocaleData(localeFR, "fr-BE");
registerLocaleData(localeNL, "nl-BE");

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      authService.getCoachByToken().subscribe().add(resolve);
    });
  };
}

/**
 * Import specific languages to avoid importing everything
 * The following will lazy load highlight.js core script (~9.6KB) + the selected languages bundle (each lang. ~1kb)
 */
export function getHighlightLanguages() {
  return [
    { name: 'typescript', func: typescript },
    { name: 'scss', func: scss },
    { name: 'xml', func: xml },
    { name: 'json', func: json },
  ];
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    FullCalendarModule,
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    HighlightModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    ToastrModule.forRoot(),
     // Import du module traductionn
     HttpClientModule,
     TranslateModule.forRoot({
         loader: {
             provide: TranslateLoader,
             useFactory: HttpLoaderFactory,
             deps: [HttpClient]
         }
     }),
     ConnectionServiceModule ,
     FormsModule, ReactiveFormsModule,
     MatIconModule,
     AgmCoreModule.forRoot({
       apiKey: env.GOOGLEMAPS_KEY,
       libraries:  ['places']
     }),
     SharedModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        languages: getHighlightLanguages,
      },
    },
    NgxIntlTelInputModule
  ],
  
  bootstrap: [AppComponent],
})
export class AppModule {}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
