<!-- splash screen -->
<div #splashScreen id="splash-screen">
  <img src="./assets/media/logos/logo_{{ brand_slug }}.svg" class="w-200px" alt="Logo" />
  <span class="spinner spinner-primary"></span>
  <!-- <svg class="splash-spinner text-primary" viewBox="0 0 50 50">
    <circle
      class="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      stroke-width="5"
    ></circle>
  </svg> -->
</div>
