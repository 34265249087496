// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    environment: 'dev',
    appVersion: '0.2.0',
    USERDATA_KEY: 'authf649fc9a5f55',
    isMockEnabled: true,
    // erpURL: "https://msdev01.mysherpa.be/",
    // apiUrlBase:"https://msdev01.mysherpa.be/api/",
    // apiUrl:"https://msdev01.mysherpa.be/api/1/",
    erpURL: "http://164.92.150.1/",
    apiUrlBase:"http://164.92.150.1/api/",
    apiUrl:"http://164.92.150.1/api/3/",
    GOOGLEMAPS_KEY: 'AIzaSyC84UwnHnMOZF6a9V9EE0aRbLaTQNTN7R4',
    crisp_website_id: '05ac56de-5424-48e3-8d64-baff056778c5',
    brand_name: "My Sherpa", //Ex: My Sherpa, Studant
    brand_slug: "mysherpa",//Ex: mysherpa, studant
    one_signal_app_id: '2845080d-f607-41cd-a4fa-be2c98f2b617',
    isCrispAvailable: false
  };